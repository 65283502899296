import { createStore } from "vuex";
import User from '../modules/User';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    User
  }
});
